import brand from '@helpers/brand';
import styled from 'styled-components';
import SearchFieldStyles from '../../Headers/SiteHeader/components/SearchField/SearchField.styles';
import { Containers } from '@stories/Widgets/YourSolution/YS/Components/Shared/Step.styles';
import { fluid } from '@helpers/fluid';
import { Device, from, until } from '@helpers/media';
import { Grid } from '@helpers/grid';
import DotPaginationStyles from '@stories/Components/Misc/DotPagination/DotPagination.styles';
import SiteWideStyles from '@stories/Components/Containers/SiteWide/SiteWide.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import { BreadcrumbsStyles } from '@stories/Widgets/Meta/Breadcrumbs/Breadcrumbs.styles';
const GridOverride = styled(Grid.Default) `
  --gridPadding: 30px;
  width: 100%;
`;
const Container = styled.div `
  position: relative;
  padding-top: ${fluid(70, 140)};
  padding-bottom: ${fluid(70, 140)};
  color: ${brand.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${fluid(300, 500)};
  overflow: hidden;
`;
const BackgroundCarousel = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const BackgroundImage = styled.img `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;
const BackgroundOverlayTint = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.36);
`;
const PaginationWrapper = styled.div `
  pointer-events: none;
  z-index: 4;

  ${DotPaginationStyles.Container} {
    pointer-events: all;
  }

  @media ${until(Device.TabletLarge)} {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;

    ${SiteWideStyles.Container} {
      display: flex;
      justify-content: center;
    }

    ${DotPaginationStyles.Container} {
      flex-direction: row;
    }
  }

  @media ${from(Device.TabletLarge)} {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;

    ${SiteWideStyles.Container} {
      display: flex;
      justify-content: flex-start;
      max-width: 1830px;
    }

    ${DotPaginationStyles.Container} {
      flex-direction: column;
    }
  }
`;
const Item = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &[aria-current='true'] {
    pointer-events: all;
  }
`;
const ContentWrapper = styled(Containers.FullWidth) `
  --searchHeight: 83px;

  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 990px;
  }

  ${SearchFieldStyles.Container} {
    position: relative;
    background: transparent;
    padding: 0;
    height: auto;
    margin-top: ${fluid(20, 50)};
  }

  ${SearchFieldStyles.Close} {
    display: none;
  }

  ${SearchFieldStyles.Input} {
    font-size: ${fluid(14, 25, Device.MobileSmall, Device.DesktopSmall)};
    // prettier-ignore
    padding: ${fluid(11, 25, Device.MobileSmall, Device.DesktopSmall)} ${fluid(11, 40, Device.MobileSmall, Device.DesktopSmall)};
    height: ${fluid(45, 83, Device.MobileSmall, Device.DesktopSmall)};
    border-radius: 0 !important;
    -webkit-border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
  }

  ${SearchFieldStyles.Submit} {
    height: ${fluid(45, 83, Device.MobileSmall, Device.DesktopSmall)};
    width: ${fluid(45, 83, Device.MobileSmall, Device.DesktopSmall)};
    flex: 0 0 ${fluid(45, 83, Device.MobileSmall, Device.DesktopSmall)};

    svg {
      width: ${fluid(17, 33, Device.MobileSmall, Device.DesktopSmall)};
      height: ${fluid(17, 33, Device.MobileSmall, Device.DesktopSmall)};
    }
  }
`;
const Heading = styled.h1 `
  font-size: ${fluid(24, 50)};
  letter-spacing: 0.02em;
  line-height: ${fluid(32, 60)};
  margin-bottom: 20px;
`;
const Paragraph = styled.p `
  ${ParagraphStyles.RegularStyles};
  font-weight: 300;
`;
const BreadcrumbWrapper = styled.div `
  position: absolute;
  width: 100%;
  z-index: 3;
  top: 0;
  left: 0;

  ${BreadcrumbsStyles.Inner} li a, ${BreadcrumbsStyles.Inner} li:after {
    color: ${brand.white};
  }
`;
export const SearchHeroBannerStyles = {
    Container,
    BackgroundCarousel,
    BackgroundImage,
    PaginationWrapper,
    BackgroundOverlayTint,
    ContentWrapper,
    Heading,
    Paragraph,
    GridOverride,
    Item,
    BreadcrumbWrapper,
};
