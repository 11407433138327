import React from 'react';
import { SearchHeroBannerStyles as S } from './SearchHeroBanner.styles';
import SearchField from '@stories/Widgets/Headers/SiteHeader/components/SearchField/SearchField';
import { imageUrl } from '@helpers/imageUrl';
import withWidget from '@hoc/withWidget';
import { HydrateOption } from '@core/enums';
import { MotionConfig } from 'framer-motion';
import DotPagination from '@stories/Components/Misc/DotPagination/DotPagination';
import SiteWide from '@stories/Components/Containers/SiteWide/SiteWide';
import SearchHeroBannerCarouselItem from './SearchHeroBannerCarouselItem/SearchHeroBannerCarouselItem';
import { withMotion } from '@hoc/withMotion';
import Breadcrumbs from '@stories/Widgets/Meta/Breadcrumbs/Breadcrumbs.widget';
const SearchHeroBanner = ({ searchResultsUrl, title, introText, images, translations, typeAheadPath, breadcrumb, }) => {
    const [activeIndex, setActiveIndex] = React.useState(0);
    return (React.createElement(S.Container, null,
        breadcrumb && (React.createElement(S.BreadcrumbWrapper, null,
            React.createElement(Breadcrumbs, { items: breadcrumb }))),
        images && images.length === 1 && images[0].src && (React.createElement(React.Fragment, null,
            React.createElement(S.BackgroundImage, { loading: "eager", src: imageUrl(images[0].src, { width: 375, dpr: 2 }), srcSet: [
                    `${imageUrl(images[0].src, { width: 1024, dpr: 1 })} 1024w`,
                    `${imageUrl(images[0].src, { width: 1920, dpr: 1 })} 1600w`,
                ].join(','), alt: images[0].alt ? images[0].alt : '', width: 375 }))),
        images && images.length > 1 && (React.createElement(S.BackgroundCarousel, null,
            React.createElement(MotionConfig, { transition: { duration: 0.45, ease: 'easeOut' } }, images.map((image, index) => (React.createElement(S.Item, { key: 'slide' + index, "aria-current": activeIndex === index },
                React.createElement(SearchHeroBannerCarouselItem, { image: image, active: activeIndex === index, index: index }))))))),
        React.createElement(S.BackgroundOverlayTint, null),
        React.createElement(S.GridOverride, null,
            React.createElement(S.ContentWrapper, null,
                title && React.createElement(S.Heading, null, title),
                introText && React.createElement(S.Paragraph, null, introText),
                searchResultsUrl && (React.createElement(SearchField, { searchResultsUrl: searchResultsUrl, showSearchMobile: true, placeholderText: translations['gf.header.search.placeholder'], searchText: translations['gf.header.search'], closeText: translations['gf.header.close'], typeAheadPath: typeAheadPath })))),
        images && images.length > 1 && (React.createElement(S.PaginationWrapper, null,
            React.createElement(SiteWide, null,
                React.createElement(MotionConfig, { transition: { duration: 0.45, ease: 'easeOut' } },
                    React.createElement(DotPagination, { count: images.length, value: activeIndex, onChange: (index) => setActiveIndex(index) })))))));
};
export default withWidget(withMotion(SearchHeroBanner), 'SearchHeroBanner', {
    hydrate: HydrateOption.InView,
});
