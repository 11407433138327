import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled(m.section) `
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;
const Image = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const SearchHeroBannerCarouselItemStyles = {
    Container,
    Image
};
